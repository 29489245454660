/* 
  服务指南相关接口
*/

import req from '@/utils/request' //引入暴露出来的请求函数

export default {
  // 获取服务指南列表
  findList: params => req.get('/api/serviceGuide/list/all', params),
  // 获取服务服务指南详情
  getDetail: params => req.get('/api/serviceGuide/detail', params),
}
